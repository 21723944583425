.hero{
    width: 100%;
    height: 100vh;
    position: relative;
}
.hero-mid{
    width: 100%;
    height: 80vh;
    position: relative;
}
.hero-mid h1{
    padding-top: 4rem !important;
}
img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.hero-text{
    position:absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}
.hero-text h1{
    padding-top: 3rem;
    font-weight: 800;
    background: white;
    -webkit-background-clip:text;
    background-clip:text;
    -webkit-text-fill-color: transparent;
}
.hero-text p{
    font-size: 1.3rem;
    color: white;
    padding: 0.5rem 0 2rem 0;
}
.hero-text .show{
    text-decoration: none;
    background: white;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: black;
}
.hero-text .hide{
    display: none;
}

@media screen and (max-width: 555px) {
    .hero-text h1{
         margin-top: 3rem;
        padding: 10px 20px ; 
    }
    .hero-text p{
        font-size: 1.1rem;
        padding: 0 0 2rem 0;
    }
    .hero-text .show{
        padding: 0.6 rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
       
    }
    
}