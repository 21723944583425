.search-trip{
    padding: 8rem 6rem;
    color: #2a2a2a;
}
.search-trip form{
    padding-top: 2rem;
    display: flex;
    justify-content: space-evenly;
}
.search-trip form input{
    height: 2rem;
    padding: 0 1rem;
    margin-bottom: 1.5rem;
    border-radius: 0.3rem;
    border: 1px solid #2a2a2a;
}

.search-trip button{
    padding-top: 0.5rem;
    text-align: center;
}
.search-trip p{
    padding-top: 0.5rem;
    text-align: center;
    color: green;
}