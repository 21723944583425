.destination{
    margin: 4rem 6rem;
}
.destination h1{
    font-size: 3rem;
}
.first-des{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4.5rem;
}
.first-des-reverse{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: 4.5rem;
}
.des-text{
    width: 45%;
    text-align: start;
    font-size: 1rem;
}
.des-text h2{
    padding-bottom: 1rem;
}
.images{
    display: flex;
    justify-content: space-between;
    width: 50%;
    position: relative;
    z-index: -99;
}
.images img{
    width: 49%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 60px -18px rgba(0,0,0,0.19);
}
.images img:nth-child(2){
    position: absolute;
    top: -10%;
    right: 0;
}

@media screen and (max-width:850px){
    .destination{
        margin: 4rem 2rem;
    }
    .first-des{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 3rem;
    }
    .first-des-reverse{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 3rem;
    }
    .des-text{
        width: 100%;
    }
    .des-text h2{
      text-align: center;
    }
    .images{
        width: 100%;
        margin: 1.8rem 0;
    }
    .images img{
        height: 250px;
    }
}